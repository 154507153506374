import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import DesctopHeader from './DesctopHeader/DesctopHeader';
import MobileHeader from './MobileHeader/MobileHeader';

import './Header.scss';

const Header: React.FC = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  return <header>{breakpoints.laptops ? <DesctopHeader /> : <MobileHeader />}</header>;
};

export default Header;
