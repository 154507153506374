import PhoneIcon from '../assets/icons/phone-socials.svg';
import TelegramIcon from '../assets/icons/telegram-socials.svg';
import ViberIcon from '../assets/icons/viber-socials.svg';

export default [
  {
    Icon: PhoneIcon,
    href: 'https://wa.me/380637300303',
  },
  {
    Icon: TelegramIcon,
    href: 'https://t.me/+380637300303',
  },
  {
    Icon: ViberIcon,
    href: 'viber://chat?number=%2B380637300303',
  },
];
