import React, { useContext } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Container from '../Container/Container';

import { IMenuQuery } from '../Header/Types';
import socials from '../../constants/socials';

import footerNav from '../../constants/footerNav';
import FooterLogo from '../../assets/icons/footer-logo.svg';

import './Footer.scss';

const CONTACTS_QUERY = graphql`
  query {
    contacts: allCockpitContacts(filter: { lang: { eq: "ua" } }) {
      nodes {
        icon {
          value
        }
        value {
          value
        }
      }
    }
  }
`;

const Footer: React.FC = (): JSX.Element => {
  const {
    contacts: { nodes: contactsNodes },
  } = useStaticQuery(CONTACTS_QUERY);

  return (
    <footer className="footer">
      <Container className="footer__container">
        <div className="footer__logo">
          <Link to="/">
            <FooterLogo />
          </Link>
        </div>

        <div className="footer__nav">
          {footerNav.map(({ id, title, link }) => (
            <Link className="footer__nav-item" key={id + title} to={link}>
              {title}
            </Link>
          ))}
        </div>
        <div className="footer__contacts">
          {contactsNodes.map(({ icon, value }) => (
            <div className="footer__contacts-item" key={value.value}>
              <div dangerouslySetInnerHTML={{ __html: icon.value }} />
              <a
                className={!value.value.includes('@') && `binct-phone-number-1`}
                href={value.value.includes('@') ? `mailto:${value.value}` : `tel:${value.value}`}
              >
                {value.value}
              </a>
            </div>
          ))}
        </div>
        <div className="footer__socials">
          {socials.map(({ Icon, href }, idx) => (
            <a href={href} target="_blank" rel="noreferrer" className="footer__socials-item" key={href + idx}>
              <Icon />
            </a>
          ))}
        </div>
      </Container>
      {/* <div className="footer-container">
        <div className="footer-info">
          <div className="footer-logo-and-text">
            <HeaderLogo />
            <p className="footer-text-under-logo">{TRANSLATE[language as 'ru' | 'ua'].footerUnderLogo}</p>
          </div>
          <div className="divider" />
          <div className="footer-grid">
            <Contacts>
              <h2>{TRANSLATE[language as 'ru' | 'ua'].contacts}</h2>
            </Contacts>
            <AddPages />
            <Subscribe />
            <ConnectWithUs />
          </div>
        </div>
        <div className="copyright-wrapper">
          <p className="copyright">{TRANSLATE[language as 'ru' | 'ua'].copyrightTitle}</p>
          <a href="https://dnc.net.ua/">
            made by D&C Development
            <DnCLogo />
          </a>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;
