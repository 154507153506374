import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from 'gatsby';

import MobileCatacogIcon from '../../assets/icons/mobile-catalog.svg';

import Categories from './CategoriesHeader';
import SubCategories from './SubCategories';

import { ICatalogProps, ISubCategory } from './Types';

import './CatalogHeader.scss';

const CatalogHeader: React.FC<ICatalogProps> = ({ isMobile }): JSX.Element => {
  const [category, setCategory] = useState<ISubCategory>();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const categoriesRef = useRef<HTMLDivElement>();
  const breakpoints = useBreakpoint();

  const handleClickOutside = useCallback(e => {
    if (categoriesRef && !categoriesRef.current.contains(e.target) && !e.target.classList.contains('catalog-container')) {
      setIsOpened(false);
    }
  }, []);

  const handleCatalogOpen = (): void => {
    setIsOpened(!isOpened);
  };

  useEffect(() => {
    if (isMobile) {
      if (isOpened) {
        document.documentElement.classList.add('fixed');
      } else {
        document.documentElement.classList.remove('fixed');
      }
    }
  }, [isOpened]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`catalog-container ${isOpened ? 'active' : ''}`} ref={categoriesRef}>
      {breakpoints.laptops ? (
        <>
          <div className="catalog-title" onClick={handleCatalogOpen}>
            <div className="catalog-icon">
              <MobileCatacogIcon />
            </div>
            Каталог
          </div>
          <div className="catalog-decisons">
            <Link to="/solutions">Будівельні рішення</Link>
          </div>
        </>
      ) : (
        <div className="catalog__icon" onClick={handleCatalogOpen}>
          <MobileCatacogIcon />
        </div>
      )}
      {!!category && <SubCategories isActive={isOpened} category={category?.subCategory || ''} index={category?.index || 0} />}
      <Categories isActive={isOpened} link={category?.subCategory || ''} setActive={setIsOpened} setCategory={setCategory} />
    </div>
  );
};

export default CatalogHeader;
