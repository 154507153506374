export default [
  {
    id: 1,
    title: 'Головна',
    link: '/',
  },
  {
    id: 2,
    title: 'Каталог товарів',
    link: '/catalog',
  },
  {
    id: 3,
    title: 'Будівельні рішення',
    link: '/solutions',
  },
  {
    id: 4,
    title: 'Інструкція для юридичних осіб',
    link: '/instruction',
  },
  {
    id: 5,
    title: 'Політика конфіденційності',
    link: '/security',
  },
  {
    id: 6,
    title: 'Договір публічної оферти',
    link: '/public-offer',
  },
];
