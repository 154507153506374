import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

import { ILayoutProps, IOffice, IOfficeQuery } from '../components/Types';
import { IProductTypes } from '../components/CartForm/Types';

import { LANGUAGES } from '../constants/languages';
import { REALMS_OFFICES, OFFICES_ID } from '../constants/realmsOffices';

import context from '../context/context';

import './layout.scss';
import Modal from '../components/Modal/Modal';
import { IContactsQuery } from '../components/Contacts/Types';
// import GeolocationModal from '../components/GeolocationModal/GeolocationModal';

const OFFICES_QUERY = graphql`
  {
    allCockpitOffices(filter: { lang: { ne: "any" } }) {
      nodes {
        lang
        id
        address {
          value
        }
        city {
          value
        }
        email {
          value
        }
        phone {
          value
        }
      }
    }
    contacts: allCockpitContacts(filter: { lang: { ne: "any" } }) {
      nodes {
        icon {
          value
        }
        lang
        value {
          value
        }
        type {
          value
        }
      }
    }
  }
`;

const Layout: React.FC<ILayoutProps> = ({ children, location }): JSX.Element => {
  const { pathname } = location; // Too lazy to rewrite shit
  const {
    allCockpitOffices: { nodes },
    contacts: { nodes: contacts },
  }: IOfficeQuery & IContactsQuery = useStaticQuery(OFFICES_QUERY);

  const [language, setLanguage] = useState<string>('ua');

  const offices = nodes.filter(({ lang }) => lang === language);

  const [office, setOffice] = useState<IOffice>(offices[0]);
  const [products, setProducts] = useState<IProductTypes[]>([]);
  // const [isGeolocationModalOpen, changeGeolocationModalOpen] = useState(false);

  useEffect(() => {
    setProducts(JSON.parse(localStorage.getItem('products')) || []);
  }, []);

  // const closeGeolocationModal = () => {
  //   changeGeolocationModalOpen(false);
  // };

  // const showGeolocationModal = () => {
  //   if (!localStorage.getItem('geolocationModalShown')) {
  //     changeGeolocationModalOpen(true);
  //     localStorage.setItem('geolocationModalShown', '1');
  //   }
  // };

  // useEffect(() => {
  //   if (typeof window !== 'undefined' && navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(position => {
  //       const {
  //         coords: { latitude, longitude },
  //       } = position;
  //       fetch(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`)
  //         .then(res => res.json())
  //         .then(result => {
  //           if (Object.keys(REALMS_OFFICES).includes(result.principalSubdivision)) {
  //             setOffice(offices.find(val => val.id === `${OFFICES_ID[result.principalSubdivision]}_${language}`));
  //           }

  //           Object.keys(REALMS_OFFICES).forEach(realmOffice => {
  //             if (REALMS_OFFICES[realmOffice].includes(result.principalSubdivision)) {
  //               setOffice(offices.find(val => val.id === `${OFFICES_ID[realmOffice]}_${language}`));
  //             }
  //           });
  //         })
  //         .catch(err => {
  //           console.error(err);
  //         })
  //         .finally(showGeolocationModal);
  //     }, showGeolocationModal);
  //   }
  // }, []);

  useEffect(() => {
    const lang = pathname.split('/')[1];
    setLanguage('ua');
    const header = document.querySelector('header');
    if (typeof window !== 'undefined' && header) header.scrollIntoView(true);
  }, [pathname]);

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('office_id', office.id.slice(0, -3));
    }
  }, [office]);

  // useEffect(() => {
  //   if (typeof localStorage !== 'undefined') {
  //     const officeId = localStorage.getItem('office_id');
  //     if (officeId !== null) {
  //       setOffice(offices.find(val => val.id === `${officeId}_${language}`));
  //     }
  //   } else {
  //     setOffice(offices[0]);
  //   }
  // }, [language]);

  return (
    <context.Provider
      value={{
        contacts: contacts.filter(({ lang }) => lang === language),
        language,
        office,
        offices,
        location,
        pathname,
        setLanguage,
        setOffice,
        products,
        setProducts,
      }}
    >
      <Header />
      <main>{children}</main>
      <Footer />
      {/* {isGeolocationModalOpen && (
        <Modal close={closeGeolocationModal}>
          <GeolocationModal office={office} offices={offices} changeOffice={setOffice} close={closeGeolocationModal} />
        </Modal>
      )} */}
    </context.Provider>
  );
};
export default Layout;
