import React, { useContext } from 'react';

import context from '../../../context/context';

import Phone from '../../../assets/icons/phone.svg';
import Email from '../../../assets/icons/mail.svg';
import Marker from '../../../assets/icons/marker.svg';

import { IChildren } from '../../Types';

import './MobileContacts.scss';
import ContactTypes from '../../../enums/contact-types.enum';

const linkProtocol = {
  [ContactTypes.Email]: 'mailto',
  [ContactTypes.Phone]: 'tel:',
};

const Contacts: React.FC<IChildren> = ({ children }): JSX.Element => {
  const { contacts } = useContext(context);

  return (
    <div className="mobile-contacts">
      {children}
      {contacts.map(({ icon: { value: icon }, type: { value: type }, value: { value } }) =>
        type !== ContactTypes.Address ? (
          <a key={value} href={`${linkProtocol[type]}:${value}`} className="mobile-contacts-item">
            <div dangerouslySetInnerHTML={{ __html: icon }} />
            {value}
          </a>
        ) : (
          <p key={value} className="mobile-contacts-item">
            <div dangerouslySetInnerHTML={{ __html: icon }} />
            {value}
          </p>
        )
      )}
    </div>
  );
};

export default Contacts;
