import React, { useContext, useState } from 'react';

import socials from '../../constants/socials';
import PhoneIcon from '../../assets/icons/phone.svg';

import './HeaderContacts.scss';
import context from '../../context/context';
import ContactTypes from '../../enums/contact-types.enum';

const HeaderContacts: React.FC = () => {
  const { contacts } = useContext(context);
  const [isSocialsOpened, setSocialsOpened] = useState<boolean>(false);
  const {
    value: { value: phone },
  } = contacts.find(({ type: { value } }) => value === ContactTypes.Phone);
  return (
    <div className="header-contacts">
      <PhoneIcon />
      <div className={`header-contacts__number ${isSocialsOpened ? 'opened' : ''}`} onClick={() => setSocialsOpened(!isSocialsOpened)}>
        {phone}
      </div>
      {isSocialsOpened ? (
        <div className="header-contacts__socials">
          {socials.map(({ Icon, href }) => (
            <a href={href} target="_blank" rel="noreferrer">
              <Icon />
            </a>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
    // <Link className="header-cart" to={link}>
    //   <CartIcon />
    //   {products?.length > 0 && <span className="header-cart-count">{products.length}</span>}
    // </Link>
  );
};

export default HeaderContacts;
