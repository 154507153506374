import React, { useContext, useState, useEffect } from 'react';

import HeaderLogo from '../HeaderLogo';
import NavBtn from '../NavBtn';
import Menu from '../Menu';
import Container from '../../Container/Container';
import CatalogHeader from '../CatalogHeader';
import SearcherMobile from './SearcherMobile';

import context from '../../../context/context';

import PhoneIcon from '../../../assets/icons/phone.svg';

import './MobileHeader.scss';
import ContactTypes from '../../../enums/contact-types.enum';

const MobileHeader: React.FC = (): JSX.Element => {
  const { contacts, pathname } = useContext(context);

  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const {
    value: { value: phone },
  } = contacts.find(({ type: { value } }) => value === ContactTypes.Phone);

  useEffect(() => {
    setMenuOpen(false);
    document.body.classList.remove('fixed');
  }, [pathname]);

  return (
    <>
      <Container className="mobile-header">
        <HeaderLogo />
        <div className="mobile-header__icons">
          <a href={`tel: ${phone}`} className="mobile-header__phone">
            <PhoneIcon />
          </a>
          <SearcherMobile />
          {/* <HeaderCart /> */}
          <CatalogHeader isMobile />

          <NavBtn isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
        </div>
      </Container>

      {/* <Categories /> */}
      <Menu isMenuOpen={isMenuOpen} />
    </>
  );
};
export default MobileHeader;
