/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
export const LANGUAGES = {
  ru: '/',
  ua: '/ua/',
};

export const TRANSLATE = {
  ru: {
    addToCart: 'Добавить в корзину',
    oneClickBuy: 'Купить в 1 клик',
    contactFormTitle: 'Ваше уникальное предложение',
    contactFormText: 'Оставьте свои контакты и мы свяжемся с Вами в самое ближайшее время.',
    contactFormAgreement: 'Я согласен с условиями обработки персональных данных.',
    contactsHereToHelp: 'Мы здесь что бы помочь',
    contactsHereToHelpText: `На сегодняшний день сеть филиалов Construction Guide насчитывает 9 представительств. Мы обеспечиваем логистику из наших складов по всей Украине. Если у Вас остались вопросы, мы с радостью ответим на них!`,
    vacanciesTitle: 'Вакансии',
    citySelect: 'Выберите Ваш город',
    callback: 'Заказать звонок',
    catalog: 'Каталог товаров',
    orderNow: 'Заказать сейчас',
    orderSteps: 'Этапы заказа',
    bannerTitle: 'Наша задача - <br>сделать Ваше строительство простым и качественным',
    bannerText:
      'Компания Construction Guide берет на себя все хлопоты, связанные с ремонтом и строительством. Проконсультируем, подберем и доставим материалы, найдем лучших специалистов.<br/> Ассортимент РКЦ насчитывает всю необходимую продукцию как для коттеджного и малоэтажного строительства, так и для строительства крупных промышленных объектов.',
    contacts: 'Контакты',
    failedOrder: 'Упс, что-то пошло не так',
    subscribe: 'Подписывайтесь на нас',
    addpages: 'Дополнительные страницы',
    connectwithus: 'Cвязаться с нами',
    advantagesTitle: 'преимущества работы с нами',
    advantagesText: `Construction Guide — ведущий поставщик строительных материалов от наиболее востребованных отечественных и зарубежных производителей.  С 2002 года мы являемся официальным торговым партнером известного бренда Технониколь.`,
    orderStepsTitle: 'этапы заказа',
    orderStepsText: `Мы заботимся о нашем клиенте.<br>Заказывая у нас, Вы получаете максимальное качество продукта и услуг. Каждый заказ для нас важен, потому мы сопровождаем клиента на всех этапах, что является гарантией качества и спокойствия.`,
    searchPlaceholder: 'Искать здесь',
    questionsTitle: 'остались вопросы?',
    questionsText:
      'У вас есть вопросы о наших продуктах, услугах или компании?<br>Здесь вы можете задать вопрос, и специалист РКЦ ответит вам лично.',
    leaveRequest: 'Оставить заявку',
    geolocationQuestion: 'Вы находитесь в г.',
    yes: 'Да',
    no: 'Нет',
    confirm: 'Подтвердить',
    chooseCity: 'Выберите город в котором вы находитесь',
    mainOffice: 'Главный офис',
    productAbout: 'Все о товаре',
    productScopes: 'Сферы использования',
    productPrice: 'Цена:',
    productPairs: 'Покупают вместе',
    subCategory: 'Подкатегории товаров',
    productRecommended: 'Так же вас могут заинтересовать',
    productBigPrice: 'Цену при большом обьеме заказа уточняйте',
    productGallery: 'Фотогалерея товара',
    productCode: 'Код товара:',
    primaryPage: 'Главная',
    available: 'Есть в наличии',
    unavailable: 'Нету в наличии',
    stockPage: 'Акции и скидки',
    productAdded: 'Товар добавлен в корзину',
    downloadPriceList: 'Скачать прайс-лист',
    sendRequest: 'Заказать',
    selectYourCity: 'Выберите ваш город',
    successOrder: 'Спасибо, оператор свяжется с вами в ближайшее время',
    formPlaceholderName: 'Введите Ваше имя',
    formPlaceholderPhone: 'Введите Ваш номер телефона',
    formPlaceholderEmail: 'Введите Ваш e-mail адрес',
    formPlaceholderPosition: 'Введите должность',
    formAgreement: 'Я согласен с условиями обработки персональных данных.',
    formTitleProposal: 'Ваше предложение по работе',
    formSubtitleProposal: 'Оставьте контакты',
    fillialInCity: 'Филиал в г. ',
    copyrightTitle: '© Copyright - Все права защищены 2020. RCKCompany',
    thankYouTitle: 'Спасибо за заказ!',
    thankYouText: 'Мы получили оплату и скоро с Вами сважется специалист',
    e404Title: 'Похоже, мы не можем найти нужную вам страницу',
    e404Text: 'Переходите на главную страницу – там Вы сможете найти интересующую Вас информацию.',
    returnToHome: 'Перейти на главную',
    error: 'Ошибка',
    stocksTitle: 'наши акции и скидки',
    stockProducts: 'Акционные товары',
    stocksHeader: 'Все акции',
    ourPartners: 'Наши партнеры',
    outOfStock: 'Нету в наличии',
    buttonDetails: 'Подробнее',
    stockDeadline: 'Акция действует до:',
    happyToHelpTitle: 'мы всегда рады вам помочь!',
    getInfoNow: 'Узнайте прямо сейчас',
    stockWork: 'Акция действует до: ',
    backToStocks: 'Назад ко всем акциям',
    footerUnderLogo: `Construction Guide — ведущий поставщик строительных материалов от наиболее востребованных отечественных и зарубежных производителей. С 2002 года мы являемся официальным торговым партнером известного бренда Технониколь.`,
    underOrder: 'Под заказ',
    expecting: 'Ожидается',
  },
  ua: {
    downloadPriceList: 'Завантажити прайс-ліст',
    available: 'Є в наявності',
    addToCart: 'Додати до кошика',
    oneClickBuy: 'Швидке замовлення',
    unavailable: 'Немає в наявності',
    contactFormTitle: 'Ваша унікальна пропозиція',
    contactFormText: 'Залиште свої контакти і ми звяжемося з Вами в самий найближчий час.',
    contactFormAgreement: 'Я згоден з умовами обробки персональних даних.',
    contactsHereToHelp: 'Ми тут щоб допомогти',
    contactsHereToHelpText: `На сьогоднішній день мережа філій Construction Guide налічує 9 представництв. Ми забезпечує логістику з наших складів по всій Україні. Якщо у Вас залишилися питання, ми з радістю відповімо на них! `,
    vacanciesTitle: 'Вакансії',
    citySelect: 'Виберіть місто',
    callback: 'Замовити дзвінок',
    catalog: 'Каталог товарів',
    orderNow: 'Замовити зараз',
    orderSteps: 'Етапи замовлення',
    bannerTitle: 'Наше завдання - <br> зробити Ваше будiвництво простим і якісним',
    bannerText:
      "Компанія Construction Guide бере на себе всі клопоти, пов'язані з ремонтом і будівництвом. Проконсультуємо, підберемо і доставимо матеріали, знайдемо кращих фахівців.<br/> Асортимент РКЦ налічує всю необхідну продукцію як для котеджного і малоповерхового будівництва, так і для будівництва великих промислових об'єктів.",
    contacts: 'Контакти',
    subscribe: 'Підписуйтесь на нас',
    addpages: 'Додаткові сторінки',
    connectwithus: 'Звязатися з нами',
    failedOrder: 'Упс, щось пішло не так',
    advantagesTitle: 'переваги роботи з нами',
    advantagesText: `Construction Guide - провідний постачальник будівельних матеріалів від найбільш затребуваних вітчизняних і зарубіжних виробників. З 2002 року ми є офіційним торговим партнером відомого бренду Техноніколь.`,
    orderStepsTitle: 'етапи замовлення',
    orderStepsText: `Ми піклуємося про нашого клієнта. <br> Замовляючи у нас, Ви отримуєте максимальну якість продукту і послуг. Кожне замовлення для нас важливий, тому ми супроводжуємо клієнта на всіх етапах, що є гарантією якості і спокойствія.`,
    searchPlaceholder: 'Що Ви шукаєте?',
    questionsTitle: 'залишилися питання?',
    questionsText:
      'У вас є питання про нашу продукцію, послуги або компанії? <br> Тут ви можете задати питання, і фахівець РКЦ відповість вам особисто.',
    leaveRequest: 'Залишити заявку',
    mainOffice: 'Головний офіс',
    productAbout: 'Все про товар',
    productScopes: 'Сфери використання',
    productPrice: 'Ціна:',
    productPairs: 'Купують разом',
    subCategory: 'Подкатегории товарів',
    geolocationQuestion: 'Ви знаходитесь у м.',
    yes: 'Так',
    no: 'Ні',
    confirm: 'Підтвердити',
    chooseCity: 'Оберіть місто в якому ви знаходитесь',
    productRecommended: 'Так само вас можуть зацікавити',
    productBigPrice: 'Ціну при великому обсязі замовлення уточнюйте',
    productGallery: 'Фотогалерея товару',
    productCode: 'Код товару:',
    productAdded: 'Товар доданий до кошику',
    primaryPage: 'Головна',
    stockPage: 'Акції та знижки',
    sendRequest: 'Замовити',
    selectYourCity: 'Виберіть ваше місто',
    successOrder: "Дякуємо, оператор зв'яжеться з вами в найближчим часом",
    formPlaceholderName: 'Введіть Ваше імя',
    formPlaceholderPhone: 'Введіть Ваш номер телефону',
    formPlaceholderEmail: 'Введіть Ваш e-mail адреса',
    formPlaceholderPosition: 'Введіть посаду',
    formAgreement: 'Я згоден з умовами обробки персональних даних.',
    formTitleProposal: 'Вашу пропозицію по роботі',
    formSubtitleProposal: 'Залиште контакти',
    fillialInCity: 'Філія в м.',
    copyrightTitle: '© Copyright - Всі права захищені 2020. RCKCompany',
    thankYouTitle: 'Дякуємо за замовлення!',
    thankYouText: 'Ми отримали оплату і скоро з Вами зв’яжеться спеціаліст!',
    e404Title: 'Схоже, ми не можемо знайти потрібну вам сторінку',
    e404Text: 'Переходьте на головну сторінку - там Ви зможете знайти потрібну Вам інформацію.',
    returnToHome: 'На головну',
    error: 'Помилка',
    stocksTitle: 'наші акції та знижки',
    stockProducts: 'Акційні товари',
    stocksHeader: 'Всі акції',
    ourPartners: 'Наші партнери',
    outOfStock: 'Немає в наявності',
    buttonDetails: 'Детальніше',
    stockDeadline: 'Акція діє до:',
    happyToHelpTitle: 'ми завжди раді вам допомогти!',
    getInfoNow: 'Дізнайтеся прямо зараз',
    stockWork: 'Акція діє до:',
    backToStocks: 'Назад до всіх акціях',
    footerUnderLogo: `Construction Guide - провідний постачальник будівельних матеріалів від найбільш затребуваних вітчизняних і зарубіжних виробників. З 2002 року ми є офіційним торговим партнером відомого бренду Техноніколь.`,
    underOrder: 'Під замовлення',
    expecting: 'Очікується',
  },
};

export const ABOUT_OUR_COMPANY = {
  ru: {
    title: 'О нашей компании',
    about: `Construction Guide — ведущий поставщик строительных материалов от наиболее востребованных отечественных
    и зарубежных производителей.  С 2002 года мы являемся официальным торговым партнером известного бренда Технониколь.`,
  },
  ua: {
    title: 'Про нашу компанію',
    about: `Construction Guide - провідний постачальник будівельних матеріалів від найбільш затребуваних вітчизняних
    і зарубіжних виробників. З 2002 року ми є офіційним торговим партнером відомого бренду Техноніколь.`,
  },
};

export const OUR_BRANCHES = {
  ru: {
    title: 'Наши филиалы',
    about: [
      `На сегодняшний день сеть филиалов Construction Guide насчитывает 9 представительств по всей Украине:
    в Днепре, Полтаве, Запорожье, Львове, Харькове, Киеве, Одессе, Хмельницком, Ивано-Франковске.`,
      `Каждый филиал Construction Guide имеет собственные склады, обеспечивая тем самым бесперебойные поставки топовых товарных позиций.`,
    ],
  },
  ua: {
    title: 'Наші філії',
    about: [
      `На сьогоднішній день мережа філій Construction Guide налічує 9 представництв по всій Україні:
    в Дніпрі, Полтаві, Запоріжжі, Львові, Харкові, Києві, Одесі, Хмельницькому, Івано-Франківську.`,
      `Кожна філія Construction Guide має власні склади, забезпечуючи тим самим безперебійне постачання топових товарних позицій.`,
    ],
  },
};

export const GOODS_CATEGORIES = {
  ru: {
    title: 'Категории продуктов',
    about: `Ассортимент Construction Guide насчитывает всю необходимую продукцию как для коттеджного и
    малоэтажного строительства, так и для строительства  крупных промышленных объектов.`,
  },
  ua: {
    title: 'Категорії продуктів',
    about: `Асортимент Construction Guide налічує всю необхідну продукцію як для котеджного і
    малоповерхового будівництва, так і для будівництва великих промислових об'єктів.`,
  },
};

export const CONTACTS_STEPS = {
  ru: [
    'Заполните форму обратной связи или воспользуйтесь кнопкой обратного звонка либо звоните нам по телефонам.',
    'Наши квалифицированные мастера внимательно выслушают Вашу проблему и предложат оптимальный вариант актуальный именно для Вас!',
    'Специалисты компании приедут со всеми необходимыми материалами и приспособления в оговоренное место для проведения ремонтных работ.',
    `Специалисты нашей компании проведут необходимы монтажные либо ремонтные
    работы связанные с кровлей вашего помещения и сдадут работу четко в срок.`,
  ],
  ua: [
    "Заповніть форму зворотного зв'язку або скористайтеся кнопкою зворотного дзвінка або телефонуйте нам за телефонами.",
    'Наші кваліфіковані майстри уважно вислухають Вашу проблему і запропонують оптимальний варіант актуальний саме для Вас!',
    'Фахівці компанії приїдуть з усіма необхідними матеріалами та пристосування в обумовлене місце для проведення ремонтних робіт.',
    `Фахівці нашої компанії проведуть необхідні монтажні або ремонтні
    роботи пов'язані з покрівлею вашого приміщення і здадуть роботу чітко в строк.`,
  ],
};

export const CV_STEPS = {
  ru: [
    'Заполните форму обратной связи или воспользуйтесь кнопкой обратного звонка либо звоните нам по телефонам.',
    `Наши HR-менеджеры свяжутся с вами. Мы уважаем Ваше время и тщательно изучаем каждое резюме.
    И если наша должность соответствует вашему опыту и навыкам, то мы обязательно пригласим Вас на интервью.`,
    'Приглашение на интервью. На собеседовании задают вопросы, касающиеся образования, опыта работы, полученных навыков и знаний.',
    'После собеседования мы свяжемся с Вами и сообщим результаты собеседования!',
  ],
  ua: [
    "Заповніть форму зворотного зв'язку або скористайтеся кнопкою зворотного дзвінка або телефонуйте нам за телефонами.",
    `Наші HR-менеджери зв'яжуться з вами. Ми поважаємо Ваш час і ретельно вивчаємо кожне резюме.
    І якщо наша посаду відповідає вашому досвіду і навичкам, то ми обов'язково запросимо Вас на інтерв'ю.`,
    `Запрошення на інтерв'ю. На співбесіді задають питання, що стосуються освіти, досвіду роботи, отриманих навичок і знань.`,
    `Після співбесіди ми зв'яжемося з Вами і повідомимо результати співбесіди!`,
  ],
};

export const HAPPY_TO_HELP = {
  ru: [
    `Наша основная цель: помочь клиенту решить его задачи наиболее выгодным и технологически
    оправданным способом. Мы предлагаем решения, которые сэкономят ваши расходы, обеспечат быстрое
    выполнение работ и повысят срок службы зданий и фасадов.`,
    `Мы обеспечиваем наивысшее качество строительства с лучшими из доступных на рынке материалов.
    Наша команда ориентирована на долгосрочное сотрудничество и гарантирует выгодные условия партнерства
    для каждого клиента.`,
    `В любой момент вы можете обратиться к нам за консультацией, и наш специалист подберет и
    просчитает необходимые объемы материалов для вашего объекта.`,
    `Наша специализация - комплексный подбор и поставка строительных решений каждому клиенту.`,
    `Construction Guide— это ваш надежный партнер для качественного строительства.`,
  ],
  ua: [
    `Наша основна мета: допомогти клієнту вирішити його завдання найбільш вигідним і технологічно
    виправданим способом. Ми пропонуємо рішення, які заощадять ваші витрати, забезпечать швидке
    виконання робіт і підвищать термін служби будинків і фасадів.`,
    `Ми забезпечуємо найвищу якість будівництва з кращими з доступних на ринку матеріалів.
    Наша команда орієнтована на довгострокову співпрацю і гарантуємо вигідні умови партнерства
    для кожного кліента.`,
    `У будь-який момент ви можете звернутися до нас за консультацією, і наш фахівець підбере і
    прорахує необхідні обсяги матеріалів для вашого об'екта.`,
    `Наша спеціалізація - комплексний підбір і постачання будівельних рішень кожному кліенту.`,
    `Construction Guide- це ваш надійний партнер для якісного будівництва.`,
  ],
};

export const CART = {
  ru: {
    title: 'Оформление заказа',
    inOrder: 'Товары в заказе',
    totalPay: 'Всего к оплате',
    form: {
      namePlaceholder: 'Имя',
      surnamePlaceholder: 'Фамилия',
      countryPlaceholder: 'Страна',
      cityPlaceholder: 'Город',
      payMethodPlaceholder: 'Способ оплаты',
    },
    deliveryOptions: {
      novaPoshta: 'Нова Пошта',
      ukrPoshta: 'Укр Пошта',
      pickup: 'Самовывоз',
      сourier: 'Курьером',
    },
    orderBtn: 'заказать',
    address: 'Адрес',
    poshtaPlaceholder: 'Отделение',
  },
  ua: {
    title: 'Оформлення замовлення',
    inOrder: 'Товари в замовленні',
    totalPay: 'Всього до сплати',
    form: {
      namePlaceholder: "Ім'я",
      surnamePlaceholder: 'Прізвище',
      countryPlaceholder: 'Країна',
      cityPlaceholder: 'Місто',
      payMethodPlaceholder: 'Спосіб оплати',
    },
    deliveryOptions: {
      novaPoshta: 'Нова Пошта',
      ukrPoshta: 'Укр Пошта',
      pickup: 'Самовивіз',
      сourier: "Кур'єром",
    },
    orderBtn: 'замовити',
    address: 'Адреса',
    poshtaPlaceholder: 'Відділення',
  },
};
