import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { ICategoriesProps } from './Types';
import { ICategoryQuery } from '../Types';

import Category from './CategoryHeader';
import MobileCatacogIcon from '../../assets/icons/mobile-catalog.svg';
import CloseButtonIcon from '../../assets/icons/cross.svg';

import './CategoriesHeader.scss';

const MENU_QUERY = graphql`
  {
    allCockpitCategories(filter: { lang: { eq: "ua" } }) {
      nodes {
        lang
        link {
          value
        }
        title {
          value
        }
        icon {
          value {
            publicURL
          }
        }
      }
    }
    allCockpitSubCategories(filter: { lang: { eq: "ua" } }) {
      nodes {
        lang
        link {
          value
        }
        title {
          value
        }
        category {
          value {
            link {
              value
            }
          }
        }
      }
    }
  }
`;

const Categories: React.FC<ICategoriesProps> = ({ isActive, link, setActive }): JSX.Element => {
  const {
    allCockpitCategories: { nodes: categoryNodes },
    allCockpitSubCategories: { nodes: subCategoryNodes },
  }: ICategoryQuery = useStaticQuery(MENU_QUERY);

  const categoriesRef = useRef<HTMLDivElement>();

  // const handleClickOutside = useCallback(e => {
  //   if (categoriesRef && !categoriesRef.current.contains(e.target) && !e.target.classList.contains('catalog-title-wrapper')) {
  //     setActive(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  const subCategories = subCategoryNodes.filter(({ category }) => category.value !== null);

  return (
    <div
      className={`categories-header ${!isActive ? 'not-visible' : ''} ${link ? 'categories-header_close' : ''}`}
      ref={categoriesRef}
      // onScroll={e => e.preventDefault()}
    >
      <div className="categories-header__container">
        <div className="categories-header__title-wrapper">
          <div className="categories-header__title">
            <MobileCatacogIcon />
            <h1>Каталог</h1>
          </div>
          <div className="categories-header__close-button" onClick={() => setActive(false)}>
            <CloseButtonIcon />
          </div>
        </div>
        <div className="categories-header__items">
          {categoryNodes.map((category, index) => (
            <Category
              category={category}
              subCategories={subCategories.filter(
                ({
                  category: {
                    value: {
                      link: { value },
                    },
                  },
                }) => value === category.link.value
              )}
              setCategoriesActive={setActive}
              key={`category-${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
