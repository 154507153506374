import React, { useContext, useState, useEffect } from 'react';
import Container from '../../Container/Container';
import HeaderLogo from '../HeaderLogo';
import CatalogHeader from '../CatalogHeader';
import Searcher from '../Searcher';
import Navigation from '../Navigation';
import HeaderCart from '../HeaderCart';
import HeaderContacts from '../HeaderContacts';
import './DesctopHeader.scss';

const DesctopHeader: React.FC = () => {
  const [isSearcherOpened, setSearcherOpened] = useState<boolean>(false);
  return (
    <>
      <Container className="desctop-header">
        <HeaderLogo />
        <CatalogHeader />
        <div className="desctop-header__searcher-nav-group">
          <Searcher isSearcherOpen={isSearcherOpened} setSearcherOpen={setSearcherOpened} />
          <Navigation isSearcherOpen={isSearcherOpened} />
        </div>
        <HeaderCart />
        <HeaderContacts />
      </Container>
    </>
  );
};

export default DesctopHeader;
