import React from 'react';

import Navigation from './Navigation';
import Container from '../Container/Container';

import { IMenuProps } from './Types';

import './Menu.scss';

const Menu: React.FC<IMenuProps> = ({ isMenuOpen }): JSX.Element => {
  return (
    <div className={`header-menu ${isMenuOpen ? 'header-menu-open' : ''}`}>
      <Container>
        <Navigation />
      </Container>
    </div>
  );
};

export default Menu;
