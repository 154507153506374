import React, { useContext, useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery, navigate } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

// import CatalogHeader from './CatalogHeader';
import MobileContacts from './MobileHeader/MobileContacts';

import { IMenuQuery, INavigationProps } from './Types';

import HeaderCart from './HeaderCart';
import context from '../../context/context';

import './Navigation.scss';

const MENU_QUERY = graphql`
  {
    allCockpitMenu(filter: { lang: { ne: "any" } }) {
      nodes {
        lang
        link {
          value
        }
        title {
          value
        }
      }
    }
  }
`;

const Navigation: React.FC<INavigationProps> = ({ isSearcherOpen }): JSX.Element => {
  const { language } = useContext(context);
  const {
    allCockpitMenu: { nodes },
  }: IMenuQuery = useStaticQuery(MENU_QUERY);

  const links = nodes.filter(({ lang }) => lang === language);
  const breakpoints = useBreakpoint();

  return (
    <div className={`navigation ${isSearcherOpen ? 'unactive' : ''}`}>
      <div className="navigation-container">
        <nav className="menu">
          {!breakpoints.laptops && (
            <>
              <Link className="menu-link menu-link__cart" to="/cart">
                Кошик
                <HeaderCart />
              </Link>
              <Link className="menu-link" to="/solutions">
                Будівельні рішення
              </Link>
            </>
          )}
          {links.map(({ link: { value: linkValue }, title: { value: titleValue } }) =>
            linkValue === '/services' ? (
              <AnchorLink to="/?#services" key={linkValue} className="menu-link">
                {titleValue}
              </AnchorLink>
            ) : (
              <Link className="menu-link" key={linkValue} to={linkValue}>
                {titleValue}
              </Link>
            )
          )}
        </nav>
        <MobileContacts />
      </div>
    </div>
  );
};

export default Navigation;
