import React, { useState } from 'react';
import { Link } from 'gatsby';

import { ICategoryProps } from './Types';

import './CategoryHeader.scss';

const Category: React.FC<ICategoryProps> = ({
  category: {
    icon,
    link: { value: linkValue },
    title: { value: title },
  },
  setCategoriesActive,
  subCategories,
}): JSX.Element => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const isActiveHandler = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <div key={linkValue} className="categories-item">
        <div
          className={`categories-item__main-category ${isActive ? 'categories-item__main-category_active' : ''}`}
          onClick={isActiveHandler}
        >
          {icon && <img src={icon.value.publicURL} alt="category-icon" />}
          {title}
        </div>
        {!!subCategories.length && (
          <div className={`categories-item__sub-categories ${!isActive ? 'categories-item__sub-categories_unactive' : ''}`}>
            {subCategories.map(({ link: { value: subLinkValue }, title: { value: subTitle } }) => (
              <Link
                key={subLinkValue}
                className="categories-item__sub-categories-item"
                to={subLinkValue}
                onClick={() => setCategoriesActive(false)}
              >
                {subTitle}
              </Link>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Category;
